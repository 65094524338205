<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else class="text-normal">
      <h4 class="header-tablepage">Ticket Form Detail</h4>
      <b-tabs class="mt-3 mb-100px">
        <b-tab title="Form Setting" active>
          <b-row>
            <b-col sm="12" md="6" lg="4">
              <div class="header-secondary title-tabs header-title d-flex py-3">
                <b>Fields</b>
              </div>
              <FieldCardList
                :list="fieldList"
                :is-active="false"
                :fieldTypes="fieldTypes"
                @click:add="addFieldToForm"
                @afterDelete="afterDelete"
              />
            </b-col>
            <b-col sm="12" md="6" lg="8">
              <div
                class="header-secondary title-tabs header-title align-items-center"
              >
                <b>Ticket Form</b>
              </div>
              <FieldCardDetailList
                :items="formDetail.fields || []"
                :fieldTypes="fieldTypes"
                :depth="0"
                :dependentFields="dependentFields"
                :isEditable="false"
                :parentChoice="[]"
                @submit="submit"
                @afterDelete="afterDelete"
                @sortFields="sortFields"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>
    <FooterAction routePath="/setting/ticket" @submit="sendForm(0)" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import FieldCardDetailList from "@/components/ticket/FieldCardDetailList";
import FieldCardList from "@/components/ticket/FieldCardList";
export default {
  components: {
    OtherLoading,
    FieldCardDetailList,
    FieldCardList,
  },
  data() {
    return {
      value: [],
      isLoading: true,
      isBusy: false,
      isSubmit: false,
      questionList: [],
      activeIndex: 0,

      fieldList: [],
      branchList: [],
      fieldTypes: [
        { id: 1, name: "Field" },
        { id: 2, name: "Checkbox" },
        { id: 3, name: "Multiple Choice" },
        { id: 4, name: "Dropdown" },
        { id: 5, name: "DateTime" },
        { id: 6, name: "Branch dropdown" },
        { id: 7, name: "Date" },
        { id: 8, name: "Image" },
        { id: 10, name: "Title" },
        { id: 11, name: "Subtitle" },
        { id: 12, name: "Text Area" },
        { id: 13, name: "Space" },
      ],

      formDetail: {
        id: 1,
        name: "",
        url_key: "",
        valid_form: null,
        valid_to: null,
        description: "",
        active: 1,
        is_default: 0,
        fields: [],
      },

      selectedItem: {
        id: 0,
        field_type_id: 0,
        name: "",
        is_default_field: 0,
        validate_text_admin: null,
        validate_text_customer: null,
        label_admin: "",
        label_customer: "",
        is_require_submit: 0,
        is_require_close: 0,
        is_customer_view: 0,
        is_customer_edit: 0,
        is_customer_require_submit: 0,
        ticket_form_id: 0,
        ticket_field_id: 0,
        sort_order: 0,
        is_required: 0,
        is_dependent: 0,
        deleted: 0,
        field_choice: [],
        field_condition: [],
        field_dependent: [],
      },

      actionType: 1,
      isDependent: false,
      selectedFieldIndex: 0,
      dependentFields: [],
    };
  },
  computed: {},

  async created() {
    await this.getDependentField();
    this.getFormDetail();
  },
  methods: {
    async getDependentField() {
      const { data } = await this.axios(`/ticket/field/filter_dependent`);
      if (data.result == 1) {
        this.dependentFields = data.detail;
      }
    },
    async getFieldType() {
      await this.$store.dispatch("getFieldTypes");
      let data = this.$store.state.form.fieldtype;
      if (data.result == 1) {
        this.fieldTypes = data.detail.fieldTypes.filter((type) => type.id != 9);
      }
    },

    mapFieldFromDepth(fields, parentId = null) {
      if (!fields?.length) return [];
      return fields
        .filter((el) => el.parent_id === parentId)
        .map((el) => {
          const dependentChoice = this.dependentFields.filter(
            (item) => item.id !== el.ticket_field_id
          );
          return {
            ...el,

            dependentChoice,
            field_dependent: this.mapFieldFromDepth(
              fields,
              el.ticket_form_field_id
            ).sort((a, b) => a.depen_on_choice_id - b.depen_on_choice_id),
          };
        });
    },

    async getFormDetail() {
      const { data } = await this.axios(`/ticket/form/1`);
      if (data.result === 1) {
        data.detail.fields.forEach((element) => {
          element.field_dependent = this.mapFieldFromDepth(
            element.field_dependent,
            element.id
          );
        });

        this.formDetail = data.detail;

        this.getField();
        this.getFieldType();
      }
    },
    async getField() {
      const { data } = await this.axios(`/ticket/list_field`);

      const selectedField = this.formDetail.fields.map(
        (el) => el.ticket_field_id
      );
      this.fieldList = [...data.detail.custom_fields].filter(
        (el) => !selectedField.includes(el.id)
      );

      for (const field of this.fieldList) {
        if (field.field_type_id === 6) {
          await this.getBranchList(field.branch_group_id ?? 0, field);
        }
      }

      this.isLoading = false;
    },
    handleCheckRequired(val, items) {
      if (val) {
        this.$nextTick(
          () => (items.is_show = 1),
          (items.show_on_edit_profile = 1)
        );
      }
    },
    disabledField(items) {
      if (
        items.field_profile_type_id == 3 ||
        items.field_profile_type_id == 5 ||
        items.field_profile_type_id == 10
      ) {
        return true;
      }
      if (items.is_profile_required) {
        return true;
      }
      if (this.isRegister) {
        return true;
      }
      return false;
    },
    async switchProfileIndex(items, index, val) {
      const targetIndex = val ? index + 1 : index - 1;
      const targetItem = this.formDetail.fields[targetIndex];

      this.formDetail.fields[targetIndex].sort_order = val
        ? targetItem.sort_order - 1
        : targetItem.sort_order + 1;
      this.formDetail.fields[index].sort_order = val
        ? this.formDetail.fields[index].sort_order + 1
        : this.formDetail.fields[index].sort_order - 1;

      this.formDetail.fields.sort((a, b) => a.sort_order - b.sort_order);
    },

    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index].name;
        }
    },

    flatFields(data) {
      return data.reduce((acc, x) => {
        acc = acc.concat(x);
        if (x.field_dependent) {
          acc = acc.concat(this.flatFields(x.field_dependent));
          x.field_dependent = [];
        }
        return acc;
      }, []);
    },

    async sendForm() {
      const fields = JSON.parse(JSON.stringify(this.formDetail.fields)).map(
        (el) => {
          if (el.field_dependent)
            el.field_dependent = this.flatFields(el.field_dependent);
          return { ...el, field_dependent: el.field_dependent };
        }
      );

      const payload = {
        ...this.formDetail,
        fields,
      };

      this.$bus.$emit("showLoading");

      const { data } = await this.axios.post(`/ticket/form`, payload);

      this.$bus.$emit("hideLoading");
      if (data.result == 1) {
        this.isLoading = false;

        this.successAlert().then(() => {
          this.$router.push("/setting");
        });
      } else {
        this.isLoading = false;
        this.errorAlert(data.message);
      }
    },

    deleteQuestion() {
      this.fieldList.splice(this.activeIndex, 1);
      if (this.fieldList.length == 0) {
        this.activeAddition = false;
        this.activeIndex = 0;
      }
    },
    async getBranchList(id, field) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
    },

    addFieldToForm(field) {
      this.formDetail.fields.push({
        ...field,
        id: 0,
        ticket_form_id: 1,
        ticket_field_id: field.id,
        deleted: 0,
        sort_order:
          this.formDetail.fields[this.formDetail.fields.length - 1].sort_order +
          1,
      });
      const selectedField = this.formDetail.fields.map(
        (el) => el.ticket_field_id
      );

      this.fieldList = [...this.fieldList].filter(
        (el) => !selectedField.includes(el.id)
      );
    },
    editField(item) {
      this.isDependent = false;
      this.selectedItem = item;
      this.actionType = 2;
      this.$refs.sidebarForm.show();
    },

    labelDescription(item) {
      if ([2, 3, 4].includes(item.field_type_id)) {
        return item.field_choice.map((el) => el.name).join(", ");
      } else return item.label_admin;
    },
    submit(items) {
      this.formDetail.fields = [...items];
      this.$forceUpdate();
    },
    afterDelete() {
      this.formDetail.fields
        .map((el, index) => {
          return { ...el, sort_order: index + 1 };
        })
        .sort((a, b) => a.sort_order - b.sort_order);
      this.getField();
    },
    sortFields() {
      this.formDetail.fields.sort((a, b) => a.sort_order - b.sort_order);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tabs {
  min-height: 66px;
}

::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
.header-title {
  display: flex;
  padding: 8px 16px;
  margin-top: 1px;
  justify-content: space-between;
  align-items: center;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

#footer {
  position: fixed;
  bottom: 0;
  left: 256px;
  right: 0;
  height: 10%;
  padding: 15px;
  background: #fff;
}

.mb-100px {
  margin-bottom: 100px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
