<template>
  <div class="question-detail-wrapper" :class="{ 'is-child': depth != 0 }">
    <div class="question-item-wrapper" v-if="depth == 0">
      <div class="font-bold sort">Sort Order</div>
      <div class="font-bold text-center require">
        Required <br />(When submit)
      </div>
    </div>
    <template v-for="(item, index) in items">
      <div :class="{ 'mx-0': depth != 0 }" :key="`${depth}-${index}`">
        <FieldCardDetailItem
          :item="item"
          :depth="depth"
          :fieldTypes="fieldTypes"
          :dependentFields="dependentFields"
          :index="index"
          :length="items.length"
          :parentChoice="parentChoice || []"
          :prevItem="items[index - 1]"
          @editField="editField"
          @addDependentField="addDependentField"
          @switchProfileIndex="switchProfileIndex"
          @deleteField="deleteField"
          @editDependentField="editDependentField"
          @deleteDependentField="deleteDependentField"
        />
      </div>
    </template>

    <TicketDependentSidebar
      ref="sidebarForm"
      :item="selectedItem"
      :actionType="actionType"
      :fieldTypes="fieldTypes"
      :isDependent="isDependent"
      :depth="depth"
      :dependentFields="dependentFields"
      :isEditable="isEditable"
      :parentList="items"
      @submit="onSave"
    />
  </div>
</template>

<script>
import FieldCardDetailItem from "@/components/ticket/FieldCardDetailItem.vue";
import TicketDependentSidebar from "@/components/ticket/TicketDependentSidebar";
export default {
  name: "FieldCardDetailList",
  components: {
    FieldCardDetailItem,
    TicketDependentSidebar,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    depth: {
      required: true,
      type: Number,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
    dependentFields: {
      required: true,
      type: Array,
    },
    isEditable: {
      default: true,
      type: Boolean,
    },
    parentChoice: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectedItem: {
        id: 0,
        field_type_id: 0,
        name: "",
        is_default_field: 0,
        validate_text_admin: null,
        validate_text_customer: null,
        label_admin: "",
        label_customer: "",
        is_require_submit: 0,
        is_require_close: 0,
        is_customer_view: 0,
        is_customer_edit: 0,
        is_customer_require_submit: 0,
        ticket_form_id: 0,
        ticket_field_id: 0,
        sort_order: 0,
        is_required: 0,
        is_dependent: 0,
        deleted: 0,
        field_choice: [],
        field_condition: [],
        field_dependent: [],
        branchList: [],
      },

      actionType: 1,
      isDependent: false,
      selectedFieldIndex: 0,
    };
  },
  methods: {
    isFirstOfType(item, index) {
      if (this.items[index + 1]) {
        return (
          this.items[index + 1].depen_on_choice_id !== item.depen_on_choice_id
        );
      }

      return index == 0;
    },
    async getBranchList(field, id) {
      await this.$store.dispatch("getBranch", parseInt(id));
      field.branchList = this.$store.state.coupon.branchList.detail;
    },
    async editDependentField(item, index) {
      this.selectedFieldIndex = index;
      await this.getBranchList(item, item.branch_group_id ?? 0);
      this.isDependent = true;
      this.selectedItem = item;
      this.actionType = 2;
      this.$refs.sidebarForm.show();
    },
    deleteDependentField(index) {
      this.items.splice(index, 1);
    },
    deleteField(index) {
      this.items.splice(index, 1);
      this.$emit("afterDelete");
    },
    async editField(item, index) {
      this.selectedFieldIndex = index;
      await this.getBranchList(item, item.branch_group_id ?? 0);
      this.isDependent = false;
      this.selectedItem = item;
      this.actionType = 2;
      this.$refs.sidebarForm.show();
    },

    async addDependentField(item, index) {
      this.selectedFieldIndex = index;
      this.isDependent = true;

      const fieldDependentId = await item.field_dependent?.map(
        (el) => el.depen_on_choice_id
      );

      item.field_choice = item.field_choice?.map((el) => {
        return { ...el, disabled: fieldDependentId?.includes(el.id) };
      });

      const fieldChoice = item.field_choice?.filter((el) => !el.disabled);

      const dependentChoice = await this.dependentFields.filter(
        (el) => el.id !== item.ticket_field_id
      );

      const newField = {
        ticket_form_field_id: 0,
        ticket_field_id: null,
        parent_id: this.items[index].ticket_field_id,
        depen_on_choice_id: fieldChoice[0].id,
        depth: this.depth + 1,
        root: 0,
        sort_order: item.field_dependent?.length
          ? item.field_dependent.length - 1
          : 1,
        id: 0,
        dependentChoice,
        isNew: true,
      };
      this.selectedItem = {
        ...item,
        field_dependent: item.field_dependent
          ? [...item.field_dependent, { ...newField }]
          : [{ ...newField }],
      };
      await this.getBranchList(this.selectedItem, item.branch_group_id ?? 0);

      this.actionType = 1;
      this.$refs.sidebarForm.show();
    },
    onSave(form) {
      this.items[this.selectedFieldIndex] = JSON.parse(JSON.stringify(form));

      this.$emit("submit", this.items);
      this.$forceUpdate();
      this.$refs.sidebarForm.hide();
    },

    async switchProfileIndex(item, index, val) {
      const targetIndex = val ? index + 1 : index - 1;

      this.items[index] = {
        ...this.items[targetIndex],
        sort_order: item.sort_order,
      };
      this.items[targetIndex] = {
        ...item,
        sort_order: val ? item.sort_order + 1 : item.sort_order - 1,
      };
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.question-detail-wrapper {
  overflow-x: auto;
  background-color: white;
  padding: 16px;
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;

  &.is-child {
    overflow: visible;
    height: auto;
  }

  .question-item-wrapper {
    width: 200px;
    display: flex;
    gap: 8px;
    align-items: flex-start;
    margin-top: 16px;

    .sort {
      min-width: 100px;
      text-align: center;
    }

    .require {
      min-width: 95px;
      text-align: center;
    }
  }
}
</style>
