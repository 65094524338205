<template>
  <div class="wrapper">
    <div class="question-item-wrapper">
      <div class="sort">
        <template v-if="depth == 0">
          <font-awesome-icon
            icon="chevron-circle-up"
            :class="`icon-size ${index == 0 ? 'disabled' : 'pointer'}`"
            @click.stop="$emit('switchProfileIndex', item, index, false)"
          />
          {{ item.sort_order }}
          <font-awesome-icon
            icon="chevron-circle-down"
            :class="`icon-size ${index == length - 1 ? 'disabled' : 'pointer'}`"
            @click.stop="$emit('switchProfileIndex', item, index, true)"
          />
        </template>
      </div>

      <div class="detail">
        <div
          class="require"
          :class="[
            {
              'mt-30px':
                (depth > 0 && index == 0) ||
                (prevItem &&
                  prevItem.depen_on_choice_id !== item.depen_on_choice_id),
            },
          ]"
        >
          <b-form-checkbox
            class="disableCheckbox"
            :id="`checkbox-${item.parent_id}-${item.depen_on_choice_id}-${item.ticket_field_id}-${item.name}`"
            :name="`checkbox-${item.parent_id}-${item.depen_on_choice_id}-${item.ticket_field_id}-${item.name}`"
            v-model="item.is_require_submit"
            :disabled="
              Boolean(item.is_default_field) ||
              [10, 11, 13, 14].includes(item.field_type_id)
            "
            :value="1"
            :unchecked-value="0"
            @change="item.is_require_submit = $event"
          >
          </b-form-checkbox>
        </div>

        <div class="w-full">
          <div
            class="choice-label"
            v-if="
              choiceLabel(item.depen_on_choice_id) &&
              (index == 0 ||
                (prevItem &&
                  prevItem.depen_on_choice_id !== item.depen_on_choice_id))
            "
          >
            <div :style="{ marginLeft: `${depth * 20}px` }" class="detail">
              When select: {{ choiceLabel(item.depen_on_choice_id)?.name }}
              <hr />
            </div>
            <div class="space"></div>
          </div>

          <div class="detail-info">
            <div
              class="question-item-card"
              :style="{ marginLeft: `${depth * 20}px` }"
            >
              <div class="question-card-title">
                <div class="text-black font-weight-bold title">
                  {{ item.name }}:
                </div>
                <div class="type-box">
                  {{ questionTypeText(item.field_type_id) }}
                </div>
                <div class="text-light-grey text-option">
                  {{ labelDescription(item) }}
                </div>
              </div>

              <div class="question-card-action">
                <template v-if="depth > 0">
                  <u
                    class="text-light-grey pointer"
                    @click="$emit('deleteDependentField', index)"
                    >Delete</u
                  >
                  <u
                    class="text-light-grey pointer"
                    @click="$emit('editDependentField', item, index)"
                    >Edit</u
                  >
                </template>

                <font-awesome-icon
                  v-if="isDependentField(item, depth) && depth < 2"
                  icon="plus-circle"
                  class="pointer btn-add-field"
                  @click="$emit('addDependentField', item, index)"
                />
                <div v-if="item.is_default_field" class="text-light-grey">
                  Default
                </div>
              </div>
            </div>

            <div class="question-item-action">
              <b-dropdown
                v-if="depth == 0"
                right
                no-caret
                variant="link"
                toggle-class="text-decoration-none"
              >
                <template #button-content>
                  <b-icon icon="three-dots-vertical"></b-icon>
                </template>
                <b-dropdown-item-button @click="$emit('editField', item, index)"
                  >Edit</b-dropdown-item-button
                >
                <b-dropdown-item-button
                  v-if="!Boolean(item.is_default_field)"
                  @click="$emit('deleteField', index)"
                  >Delete</b-dropdown-item-button
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="item.field_dependent && item.field_dependent.length"
      class="child-wrapper"
    >
      <FieldCardDetailList
        :items="item.field_dependent"
        :depth="depth + 1"
        :fieldTypes="fieldTypes"
        :dependentFields="dependentFields"
        :isEditable="false"
        :parentChoice="item.field_choice || []"
        class="p-0"
        @afterDelete="$emit('afterDelete')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldCardDetailItem",
  components: {
    FieldCardDetailList: () =>
      import("@/components/ticket/FieldCardDetailList.vue"),
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    depth: {
      required: true,
      type: Number,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
    dependentFields: {
      required: true,
      type: Array,
    },
    index: {
      required: true,
      type: Number,
    },
    length: {
      required: true,
      type: Number,
    },
    parentChoice: {
      required: true,
      type: Array,
    },
    prevItem: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    choiceLabel(depen_on_choice_id) {
      return this.parentChoice.find((el) => {
        return el.id == depen_on_choice_id;
      });
    },
    labelDescription(item) {
      if ([2, 3, 4].includes(item.field_type_id)) {
        return item.field_choice.map((el) => el.name).join(", ");
      } else return item.label_admin;
    },
    isDependentField(item) {
      let fieldDependentByType = 1;
      item.field_dependent?.forEach((element, index) => {
        if (
          item.field_dependent[index + 1] &&
          element.depen_on_choice_id !==
            item.field_dependent[index + 1].depen_on_choice_id
        )
          fieldDependentByType += 1;
      });

      return (
        [2, 3, 4].includes(item.field_type_id) &&
        fieldDependentByType < item.field_choice.length
      );
    },
    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index].name;
        }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-item-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;

  .sort {
    width: 100px;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .detail {
    display: flex;
    gap: 8px;
    flex: 1;

    .require {
      text-align: center;
      min-width: 95px;
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .choice-label {
      display: flex;
      color: #939393;

      hr {
        flex: 1;
        border-top: 2px solid;
        border-color: rgb(0 0 21 / 5%);
        margin: 4px 0;
      }

      .space {
        width: 20px;
      }

      .detail {
        display: flex;
        gap: 6px;
        padding: 0px 10px;
        border-radius: 5px;
        margin-bottom: 10px;
        background-color: #e9e9e9;
        box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.24);
        align-items: center;
      }
    }

    .detail-info {
      display: flex;
      flex: 1;
      gap: 8px;

      .question-item-card {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 12px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);

        .question-card-title {
          display: flex;
          gap: 4px;
          align-items: center;
        }

        .question-card-action {
          display: flex;
          gap: 4px;
          align-items: center;
        }
      }

      .question-item-action {
        display: flex;
        gap: 16px;
        align-items: center;
        width: 20px;

        ::v-deep .dropdown.b-dropdown > button {
          border: none !important;
          padding: 0;
          color: #575757;
        }
      }
    }
  }
}
.wrapper {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}

.text-light-grey {
  color: #9a9a9a;
}

.btn-add-field {
  color: var(--primary-color);
  font-size: 20px;
}
.text-option {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.w-full {
  width: 100%;
}
.mt-30px {
  margin-top: 30px;
}
</style>
