<template>
  <div class="question-panel">
    <div
      v-for="(item, index) of list"
      :key="'add-' + index"
      :class="['question-card', isActive && 'active']"
      @click="$emit('click:card', item, index)"
    >
      <div class="question-card-detail">
        <div class="question-card-wrapper">
          <div class="question-card-title">
            <div class="text-black font-weight-bold">{{ item.name }}:</div>
            <div class="type-box">
              {{ questionTypeText(item.field_type_id) }}
            </div>
          </div>
        </div>
        <div class="text-light-grey line-clamp-1">
          {{ labelDescription(item) }}
        </div>
      </div>
      <div class="question-card-action">
        <b-button class="add-btn" @click="$emit('click:add', item, index)">
          <b-icon icon="plus"></b-icon
        ></b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    list: {
      required: true,
      type: Array,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    fieldTypes: {
      required: true,
      type: Array,
    },
  },

  async created() {},
  methods: {
    questionTypeText(type) {
      if (type != undefined)
        if (type == 0) {
          return "Customer Detail";
        } else {
          let index = this.fieldTypes.findIndex((field) => field.id == type);
          return this.fieldTypes[index] ? this.fieldTypes[index].name : "";
        }
    },
    labelDescription(item) {
      if ([2, 3, 4].includes(item.field_type_id)) {
        return item.field_choice.map((el) => el.name).join(", ");
      } else return item.label_admin;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-normal {
  color: #5b5b5b;
}
.text-light-grey {
  color: #9a9a9a;
}

.question-panel {
  height: auto;
  overflow-x: auto;
  background-color: white;
  padding: 16px;
  display: flex;
  gap: 8px;
  width: 100%;
  flex-direction: column;
}

.question-card {
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);
  border-left: 5px solid transparent;
  background-color: white;
  border-radius: 4px;
  text-overflow: ellipsis;
  padding: 12px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  min-height: 78px;

  .question-card-detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .question-card-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .question-card-title {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .question-card-action {
    display: flex;
    align-items: center;
  }

  &.active {
    border-left: 5px solid var(--primary-color);
  }
}

.type-box {
  padding: 2px 8px;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 6px;
}
.icon-size {
  font-size: 20px;
  color: #575757;
}
.icon-size.disabled {
  color: #bababa;
}

.add-btn {
  color: white;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
  padding: 4px;
}
.add-btn:hover {
  background-color: var(--primary-color);
  color: white;
  opacity: 0.8;
}
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
